import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import CoreDashboard from '../../pages/dashboard';
import CoreDashboardAffiliate from '../../pages/dashboardAffiliate';

import CoreProduct from '../../pages/product';
import CoreProductAffiliate from '../../pages/productAffiliate';

import CoreTransactions from '../../pages/transactions';
import CorePrint from '../../pages/print';
import CoreSearch from '../../pages/search';
import CoreSem from '../../pages/sem';
import CoreAdsales from '../../pages/adsales';
import CoreIndustry from '../../pages/industry';
import CoreConversions from '../../pages/conversions';
import CoreExperiments from '../../pages/experiments';
import CoreGa from '../../pages/ga';
import CoreContent from '../../pages/content';
import CoreOpsis from '../../pages/opsis/home';
import CoreOpsisAdmin from '../../pages/opsis/admin';
import CoreOpsisVenue from '../../pages/opsis/venue';
import CoreOpsisWeek from '../../pages/opsis/week';
import CoreAdmin from '../../pages/admin';
import CoreProducts from '../../pages/products';
import CoreCms from '../../pages/cms';
import CoreFinance from '../../pages/finance';

import { scurl, dateShortcut } from '../../core/utils.js';

import { updateFilter } from '../../actions/filter';

import Header from '../Header';
import Filter from '../Helper';
import s from './Layout.module.scss';

import Cookies from 'js-cookie';
class Layout extends React.Component {
  
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };

    this.loadUser = this.loadUser.bind(this);
    this.doLogout = this.doLogout.bind(this);
  }

  componentDidMount() {
    this.loadUser();

    var before = Object.assign({}, this.props.filter);

    if(before && before.dates && (before.dates.id !== 99)) {
      var set = {
        id: this.props.filter.dates.id
      };
  
      var dates = dateShortcut(set);
      before.dates = dates;
      this.props.dispatch(updateFilter(before));
    }
    
  }

  loadUser() {
    this.setState({
      isLoaded: false
    }, function() {
      scurl({
        path: '/users/token',
        type: 'simple'
      }, function(err, results) {
        if(err) {
          this.doLogout()
        } else {
          this.setState({
            isLoaded: true,
            user: results.user
          }, function() {
            
          });
        }
      }.bind(this));
    });
  }

  doLogout() {    
    Cookies.remove("sh_token");
    localStorage.removeItem("sh_state");
    this.props.history.push('/#/login');
  }

  render() {
    return (
      <div className={s.root} >
        {this.state.isLoaded && this.state.user && this.state.user.navigation ? (
          <div className={s.wrap}>
            <Header user={this.state.user} />
            {!this.state.user.affiliate && !this.state.user.cms_only ? (
              <Filter />
            ) : null }

              <main className={s.content}>
                <TransitionGroup>
                  <CSSTransition
                    key={this.props.location.pathname}
                    classNames="fade"
                    timeout={200}
                  >
                    <Switch>

                      <Route path="/app" exact render={() => <Redirect to="/app/dashboard" />} />

                      {this.state.user.affiliate ? (
                        <Route path="/app/dashboard" exact component={CoreDashboardAffiliate} />
                      ) : (
                        this.state.user.cms_only ? (
                          <Route path="/app/dashboard" exact component={CoreCms} />
                        ) : (
                          this.state.user.finance_only ? (
                            <Route path="/app/dashboard" exact component={CoreFinance} />
                          ) : (
                            this.state.user.admin ? (
                              <Route path="/app/dashboard" exact component={CoreDashboard} />
                            ) : (
                              <Route path="/app/dashboard" exact component={CoreTransactions} />
                            )
                          )
                        )
                      )}

                      {this.state.user.affiliate ? (
                        <Route path="/app/products/:id" exact component={CoreProductAffiliate} />
                      ) : (
                        <Route path="/app/products/:id" exact component={CoreProduct} />
                      )}

                      {this.state.user.navigation.includes(101) ? (
                        <Route path="/app/transactions" exact component={CoreTransactions} />
                      ) : null}

                      {this.state.user.navigation.includes(101) ? (
                        <Route path="/app/transactions/:tab" exact component={CoreTransactions} />
                      ) : null}

                      {this.state.user.navigation.includes(201) ? (
                        <Route path="/app/search" exact component={CoreSearch} />
                      ) : null}

                      {this.state.user.navigation.includes(201) ? (
                        <Route path="/app/sem" exact component={CoreSem} />
                      ) : null}

                      {this.state.user.navigation.includes(301) ? (
                        <Route path="/app/adsales" exact component={CoreAdsales} />
                      ) : null}

                      {this.state.user.navigation.includes(401) ? (
                        <Route path="/app/industry" exact component={CoreIndustry} />
                      ) : null}

                      {this.state.user.navigation.includes(501) ? (
                        <Route path="/app/cms" exact component={CoreCms} />
                      ) : null}

                      {this.state.user.navigation.includes(601) ? (
                        <Route path="/app/finance" exact component={CoreFinance} />
                      ) : null}

                      {this.state.user.navigation.includes(701) ? (
                          <Route path="/app/opsis" exact component={CoreOpsis} />
                      ) : null}

                      {this.state.user.navigation.includes(701) ? (
                          <Route path="/app/opsis/admin" exact component={CoreOpsisAdmin} />
                      ) : null}

                      {this.state.user.navigation.includes(701) ? (
                          <Route path="/app/opsis/venues/:id" exact component={CoreOpsisVenue} />
                      ) : null}

                      {this.state.user.navigation.includes(701) ? (
                          <Route path="/app/opsis/weeks/:id" exact component={CoreOpsisWeek} />
                      ) : null}

                      {this.state.user.navigation.includes(801) ? (
                        <Route path="/app/conversions" exact component={CoreConversions} />
                      ) : null}

                      {this.state.user.navigation.includes(801) ? (
                        <Route path="/app/ga" exact component={CoreGa} />
                      ) : null}

                      {this.state.user.navigation.includes(901) ? (
                        <Route path="/app/content" exact component={CoreContent} />
                      ) : null}

                      {this.state.user.admin ? (
                        <Route path="/app/print" exact component={CorePrint} />
                      ) : null}

                      {this.state.user.admin ? (
                        <Route path="/app/experiments" exact component={CoreExperiments} />
                      ) : null}

                      {this.state.user.admin ? (
                        <Route path="/app/admin" exact component={CoreAdmin} />
                      ) : null}

                      {this.state.user.admin ? (
                        <Route path="/app/products" exact component={CoreProducts} />
                      ) : null}

                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </main>
       
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(connect()(Layout));
