import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser } from '../../core/utils.js';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import WidgetSalesTimespans from '../../widgets/transactions/Timespans';
import WidgetBwShows from '../../widgets/transactions/Shows';
import WidgetBwLead from '../../widgets/bw/Lead';
import WidgetRepeats from '../../widgets/transactions/Repeats';
import WidgetLeads from '../../widgets/transactions/Leads';
import WidgetMargin from '../../widgets/transactions/Margin';
import WidgetPrices from '../../widgets/transactions/Prices';
import WidgetTransactionField from '../../widgets/transactions/Field';

import isScreen from '../../core/screenHelper';

import s from './Transactions.module.scss';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    let perf_date = false;
    let tabSelected = 0;
    let q = props.location.state;
    if(q && q.tab) { 
      tabSelected = q.tab; 
    } else {
      if(this.props.match.params.tab) {
        tabSelected = parseInt(this.props.match.params.tab);
      }
    }
    if(q && q.perf_date) { perf_date = q.perf_date; }

    this.state = {
      isLoaded: false,
      tabSelected: tabSelected,
      lead_groups: [
        '0-0,1-3,4-6,7-13,14-20,21-27,28-34,35-41,42-48,49-55,56-62,63-69,70-76,77-83,84-1000',
        '0-28,29-55,56-1000'
      ],
      price_groups: [
        '0-59.99,60-79.99,80-99.99,100-129.99,130-159.99,160-189.99,190-229.00,230-999.99'
      ],
      perf_date: perf_date
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs(
    
  ){

    getUser(function(err, user) {
      if(err) {
        console.log('error retreiving user');
      } else {
        var tabs = [
          {
            id: 0,
            icon: 'fa-circle-dollar-to-slot',
            name: 'Summary'
          },
          {
            id: 1,
            icon: 'fa-star',
            name: 'Shows'
          },
          {
            id: 2,
            icon: 'fa-users-gear',
            name: 'Details'
          }
        ];

        if(!user.affiliate && !this.props.filter.limit.chargeback) {
          tabs.push({
            id: 3,
            icon: 'fa-calendar-range',
            name: 'Lead Times'
          });
          tabs.push({
            id: 4,
            icon: 'fa-users',
            name: 'Repeats'
          });
          tabs.push({
            id: 5,
            icon: 'fa-bullseye',
            name: 'Margins'
          });
          tabs.push({
            id: 6,
            icon: 'fa-dollar',
            name: 'Prices'
          });
        }
        
        this.setState({
          tabs: tabs
        });
      }
    }.bind(this));

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  render() {
  
    return (
      <div className={s.root}>

        <div className="menuContainer lg">
          <Nav className="mt-4">
            <NavItem className="nopad-bottom nomargin-bottom">
                <h5 className="text-danger">Transactions</h5>
                {this.state.tabs ? (
                <ButtonGroup>
                  {this.state.tabs.map((tab, i) =>
                    <Button key={i}
                      color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                      active={this.state.tabSelected === i}
                    ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                    <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                  )}
                </ButtonGroup>
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <div>
              <WidgetSalesTimespans affiliate={false} perf_date={this.state.perf_date} />
            </div>
          ): null}

          {this.state.tabSelected === 1 ? (
            <div>
              <WidgetBwShows sales_metric="face" affiliate={false} display={1000} />
            </div>
          ): null}

          {this.state.tabSelected === 2 ? (
            <div>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div>
                    <WidgetTransactionField field="$sale_or_refund" title="Sales/Refunds" field_header="Sales Type" sales_metric="face" affiliate={false} />
                    <WidgetTransactionField field="$customer.dma" title="DMA" field_header="DMA" className="mt-3" sales_metric="face" affiliate={false} refunds={0} />
                    <WidgetTransactionField field="$customer.country" title="Country" field_header="Country" className="mt-3" sales_metric="face" affiliate={false} refunds={0} />
                    <WidgetTransactionField field="$quantity" title="Per Order QTY" field_header="QTY" className="mt-3 mb-3" sales_metric="face" affiliate={false} refunds={0} />
                    <WidgetTransactionField field="$lead_days" title="Lead Days" field_header="LEAD" className="mt-3 mb-3" sales_metric="face" affiliate={false} refunds={0} />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div>
                    <WidgetTransactionField field="$venowner" title="Venue Owner" field_header="Owner" display_length={8} sales_metric="face" affiliate={false} refunds={0} />
                    <WidgetTransactionField field="$wallet" title="Wallets" field_header="Wallet" className="mt-3" share_to_total={true} sales_metric="face" affiliate={false} refunds={0} />
                    <WidgetTransactionField field="$source_name" title="Sources" field_header="Source" className="mt-3" sales_metric="face" affiliate={false} refunds={0} />
                    <WidgetTransactionField field="$ga.source.source" title="Sources" field_header="Source" className="mt-3" sales_metric="face" affiliate={false} refunds={0} />
                    <WidgetTransactionField field="$ga.source.medium" title="Medium" field_header="Medium" className="mt-3" sales_metric="fees" affiliate={false} refunds={0} />
                  </div>
                </Col>
              </Row>
            </div>
          ): null}

          {this.state.tabSelected === 3 ? (
            <div>
              <WidgetLeads affiliate={false} lead_groups={this.state.lead_groups[0]} />
              <WidgetBwLead affiliate={false} className="mt-3" />
            </div>
          ): null}

          {this.state.tabSelected === 4 ? (
            <div>
              <WidgetRepeats affiliate={false} perf_date={this.state.perf_date} />
            </div>
          ): null}

          {this.state.tabSelected === 5 ? (
            <div>
              <WidgetMargin affiliate={false} perf_date={this.state.perf_date} />
            </div>
          ): null}

          {this.state.tabSelected === 6 ? (
            <div>
              <WidgetPrices affiliate={false} price_groups={this.state.price_groups[0]} />
            </div>
          ): null}
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Dashboard));
