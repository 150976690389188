import React, { Component } from 'react';
import cx from 'classnames';
import { ButtonGroup, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Widget from '../Widget';

import SummaryTab from './filters/Summary';
import LimitTab from './filters/Limit';
import ExcludeTab from './filters/Exclude';

import { filterUpdated, filterCount, filterState} from '../../core/utils';
import { updateFilter } from '../../actions/filter';

import s from './Helper.module.scss'; // eslint-disable-line

class Helper extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
  
    this.state = { 
      isOpened: false,
      run: false,
      filterTabSelected: 1, 
      filterCount: 0,
      filterState: null
    };

    this.changeFilterTab = this.changeFilterTab.bind(this);
    this.setCount = this.setCount.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  toggleFilter = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  componentDidMount() {
    this.setCount(this.props.filter);
    var fs = filterState(this.props.filter);
    this.setState({
      filterState: fs
    });
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'FILTER COMPONENT')) {
      this.setCount(this.props.filter);
      var fs = filterState(this.props.filter);
      this.setState({
        filterState: fs
      });
    }
  }

  changeFilterTab(tab) {
    this.setState({
      filterTabSelected: tab,
      newNotifications: null,
    });
  }

  loadNewFilters() {
    this.setState({
      isLoad: true,
    });
  }

  setCount(filter) {
    var count = filterCount(filter);
    this.setState({
      filterCount: count,
    });
  }

  clearAll() {
    
    var before = Object.assign({}, this.props.filter);

    before.limit = {
			engagements: [],
      owners: [],
			sources: [],
			devices: [],
      countries: [],
      experiments: [],
      mediums: [],
      set: true
    };

		before.exclude = {
			engagements: [],
      owners: [],
			sources: [],
			refunds: false,
			countries: [],
      mediums: [],
      set: true
    };

    this.props.dispatch(updateFilter(before));

    this.toggleFilter();
  }

  render() {
    const { isOpened } = this.state;

    let filterTab;

    switch (this.state.filterTabSelected) {
      case 1:
        filterTab = (<SummaryTab changeFilterTab={this.changeFilterTab} toggleFilter={this.toggleFilter} />);
        break;
      case 2:
        filterTab = (<LimitTab changeFilterTab={this.changeFilterTab} toggleFilter={this.toggleFilter} />);
        break;
      case 3:
        filterTab = (<ExcludeTab changeFilterTab={this.changeFilterTab} toggleFilter={this.toggleFilter} />);
        break;
      default:
        filterTab = (<SummaryTab changeFilterTab={this.changeFilterTab} toggleFilter={this.toggleFilter} />);
        break;
    }

    return (
      <div className={cx(s.themeHelper, { [s.themeHelperOpened]: isOpened })}>
          <div className={`${s.themeHelperBtn} bg-primary helper-button`} onClick={this.toggleFilter}>
            <div className={(this.state.filterCount > 0) ? 'text-white' : 'text-white'}>
                  <i className="fa-light fa-filter-list fa-2xl gutter-top-fixed-1 gutter-bottom-fixed-1" />
                  {this.state.filterCount > 0 ? (
                    <span className="fw-bold">&nbsp;&nbsp;{this.state.filterCount}</span>
                  ) : null}
                </div>
          </div>
        <Widget
          className={s.themeHelperContent}
        >
          <div className={s.helperHeader}>
              <ButtonGroup id="notification-buttons" className={[s.cardHeader, 'card-header'].join(' btn-toolbar')}>
                <Button onClick={() => this.changeFilterTab(1)} active={this.state.filterTabSelected === 1}><i className="fa-light fa-list fa-lg"></i><br/><span className="d-md-down-none">&nbsp;Summary</span></Button>
                <Button onClick={() => this.changeFilterTab(2)} active={this.state.filterTabSelected === 2}><i className={['fa-light','fa-filter','fa-lg',this.state.filterState && this.state.filterState.limit && (this.state.filterState.limit.engagements||this.state.filterState.limit.devices||this.state.filterState.limit.sources) ? 'text-danger' : null].join(' ')}></i><br/><span className="d-md-down-none">&nbsp;Limit</span></Button>
                <Button onClick={() => this.changeFilterTab(3)} active={this.state.filterTabSelected === 3}><i className={['fa-light','fa-filter-slash','fa-lg',this.state.filterState && this.state.filterState.exclude && (this.state.filterState.exclude.engagements||this.state.filterState.exclude.devices||this.state.filterState.exclude.sources) ? 'text-danger' : null].join(' ')}></i><br/><span className="d-md-down-none">&nbsp;Exclude</span></Button>
              </ButtonGroup>
          </div>

          <div className="mt-4 gutter-top-fixed-10 filter-wrap">
            {this.state.newFilter || filterTab}
          </div>

          {this.state.filterCount > 0 ? (
            <div className={['text-sm', 'card-footer'].join(' ')}>
              <Button size="sm" color="primary" onClick={((e) => this.clearAll(e))}>Clear All</Button>
            </div>
          ) : null }

        </Widget>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default connect(mapStateToProps)(Helper);
