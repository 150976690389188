import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon/build/node/luxon';

import Widget from '../../components/Widget';
import Poster from '../../components/Poster';

import { dateRangeDisplay, formatNumber, dateIsCovid } from '../../core/utils';

import { XAxis, YAxis, Tooltip, CartesianGrid, LineChart, Line, BarChart, Bar } from 'recharts';

import { Row, Col } from 'reactstrap';

import isScreen from '../../core/screenHelper';

import Chart from '../charts/Chart.js';

class TotalsChartLabel extends PureComponent {

  static propTypes = {
    format: PropTypes.string,
    bars: PropTypes.number
  }

  static defaultProps = {
    format: 'XM',
    bars: 2
  }

  constructor(props) {
    super(props);

    let thin = false;
    if(this.props.bars > 6) {
      thin = true;
    }

    let format = this.props.format;
    
    if(format === '$X') {
      if(this.props.value > 1000) {
        format = '$XM';
      }
    }

    if(format === 'X') {
      if(this.props.value > 1000) {
        format = 'XM';
      }
    }

    this.state = {
      format: format,
      thin: thin
    }
  }

  render() {
    const { x, y, value } = this.props;
      return (
        value > 0 ? (
          <text x={x} y={y} dx={16} dy={-4} fill='#777' fontSize={9} textAnchor="middle">
            {formatNumber(this.state.format,value,this.state.thin)}
          </text>
        ) : (
          <text x={x} y={y} dx={16} dy={-4} fill='#777' fontSize={9} textAnchor="middle">--</text>
        )
      );
  }
}
class WidgetDataPointCard extends React.Component {

  static propTypes = {
    week: PropTypes.object,
    product: PropTypes.object,
    print: PropTypes.bool,
    timeline_chart_width: PropTypes.number,
    timeline_chart_height: PropTypes.number,
    timeline_chart_aspect_width: PropTypes.number,
    timeline_chart_aspect_height: PropTypes.number,
    totals_chart_width: PropTypes.number,
    totals_chart_height: PropTypes.number,
    totals_chart_aspect_width: PropTypes.number,
    totals_chart_aspect_height: PropTypes.number,
    results: PropTypes.object,
    title: PropTypes.string,
    lookback: PropTypes.number,
    format: PropTypes.string,
    field: PropTypes.string,
    root_name: PropTypes.string,
    parent: PropTypes.string,
    timespan: PropTypes.string,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    show_header: PropTypes.bool,
    product_link_passthrough: PropTypes.object
  };

  static defaultProps = {
    print: false,
    timeline_chart_width: 350,
    timeline_chart_height: 170,
    timeline_chart_aspect_width: 3,
    timeline_chart_aspect_height: 1.15,
    totals_chart_width: 350,
    totals_chart_height: 170,
    totals_chart_aspect_width: 3,
    totals_chart_aspect_height: 1.15,
    format: "X",
    timespan: 'days',
    root_name: 'totals',
    xs: 12,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 6,
    show_header: true
  };

  constructor(props) {
    super(props);

    //console.log(this.props.results);

    let drd = '';
    if(this.props.results.dates && this.props.results.dates[0] && this.props.results.dates[0].lookback) {
      drd = dateRangeDisplay(this.props.results.dates[0].lookback, 'MM/dd');
    }

    this.state = {
      isLoaded: false,
      isError: false,
      timespan: this.props.timespan,
      print: this.props.print,
      lookbackRangeDisplay: drd,
      totals_chart: {
        loaded: false,
        data: [],
        properties: {
          max: 1,
          min: 1,
          col: 4,
          width: this.props.totals_chart_width,
          height: this.props.totals_chart_height,
          aspect_width: this.props.totals_chart_aspect_width,
          aspect_height: this.props.totals_chart_aspect_height
        }
      },
      timeline_chart: {
        loaded: false,
        data: [],
        properties: {
          max: 1,
          min: 1,
          width: this.props.timeline_chart_width,
          height: this.props.timeline_chart_height,
          aspect_width: this.props.timeline_chart_aspect_width,
          aspect_height: this.props.timeline_chart_aspect_height
        }
      },
      xs: this.props.xs,
      sm: this.props.sm,
      md: this.props.md,
      lg: this.props.lg,
      xl: this.props.xl
    }

    this._isMounted = false;
    this.setTimelineChart = this.setTimelineChart.bind(this);
    this.setTotalsChart = this.setTotalsChart.bind(this);
    this.chartDataFormat = this.chartDataFormat.bind(this);
    this.chartDateFormat = this.chartDateFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
    this.CustomTooltip = this.CustomTooltip.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTimelineChart();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(this.props.timespan !== prevProps.timespan) {
      this.setTimelineChart();
    }
    if(prevProps.print !== this.props.print) {
      this.setState({
        print: this.props.print
      });
    }
  }

  handleResize() {
    if(this._isMounted) {
      this.setChartAspect();
    }
  }

  CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      //console.log(payload);

      return (
        <div style={{ background: '#fff', border: '1px solid #ccc', padding: '5px 20px'}} className="text-left">

          {payload.map((block, i) =>
            block.value > -1 ? (
              <h6 key={i} className="text-black" style={{ color: payload[i].color}} >{formatNumber(this.props.format,payload[i].value)}</h6>
            ) : null
          )}
        </div>
      );
    }
  
    return null;
  };

  setTotalsChart() {

    let chartData = [];
   
    let d = {
      name: 'totals'
    };

    this.props.results.totals.forEach(function(block, i) {
      if( (i === 0) && block.lookback) {
        if(this.props.parent) {
          d.lb = block.lookback[this.props.parent][this.props.field];
        } else {
          d.lb = block.lookback[this.props.field];
        }
      }
      
      if( !this.props.results.dates || !dateIsCovid(this.props.results.dates[i]) ) {
        if(this.props.parent) {
          if(block[this.props.parent][this.props.field] > 0) {
            d['y'+i] = block[this.props.parent][this.props.field];
          } else {
            d['y'+i] = 0;
          }
        } else {
          if(block[this.props.field] > 0) {
            d['y'+i] = block[this.props.field];
          } else {
            d['y'+i] = 0;
          }
        }
      }
    }.bind(this));
    chartData.push(d);

    let max = 1;
    let min = 1;
    let aspect_width = this.props.totals_chart_aspect_width;
    let aspect_height = this.props.totals_chart_aspect_height;

    let colSize = 12;
    if(this.state.timeline_chart && this.state.timeline_chart.show) {
      colSize = 4;
      if(!this.props.show_header) {
        aspect_width = parseFloat(this.props.totals_chart_aspect_width/2);
        aspect_height = parseFloat(this.props.totals_chart_aspect_height);
      }
    } else {
      aspect_width = parseFloat(aspect_width*2);
      aspect_height = parseFloat(aspect_height);
    }

    this.setState({
      ...this.state,
      totals_chart: {
        ...this.state.totals_chart,
        loaded: true,
        data: chartData,
        properties: {
          ...this.state.totals_chart.properties,
          max: max,
          min: min,
          aspect_width: aspect_width,
          aspect_height: aspect_height
        },
        col: colSize,
        bars: this.props.results.totals.length,
        show: true
      }
    }, function() {
      this.setChartAspect();
      window.addEventListener('resize', this.handleResize.bind(this));
    });
  };

  setTimelineChart() {

    var chartData = [];

    this.props.results.timespans[this.props.timespan].forEach(function(block, i) {
      
      var d = {
        name: block.dates.end
      };

      block.years.forEach(function(year, y) {
        if( !this.props.results.dates || !dateIsCovid(this.props.results.dates[y]) ) {
          if(this.props.parent && year[this.props.parent]) {
            if( (y === 0) && year.lookback) {
              if(year.lookback[this.props.parent][this.props.field] < 0) {
                d.lb = 0;
              } else {
                d.lb = year.lookback[this.props.parent][this.props.field];
              }
            }
            if(year[this.props.parent][this.props.field] > 0) {
              d['y'+y] = year[this.props.parent][this.props.field];
            }
          } else {
          
            if( (y === 0) && year.lookback) {
              if(year.lookback[this.props.field] < 0) {
                d.lb = 0;
              } else {
                d.lb = year.lookback[this.props.field];
              }
            }
            if(year[this.props.field] > 0) {
              d['y'+y] = year[this.props.field];
            }
          }

        }
      }.bind(this));
      
      chartData.push(d);

    }.bind(this));

    let max = 1;
    let min = 1;

    let showChart = true;
    if(chartData.length <= 1) {
      showChart = false;
    }

    //console.log(showChart);

    this.setState({
      ...this.state,
      timeline_chart: {
        ...this.state.timeline_chart,
        loaded: true,
        data: chartData,
        properties: {
          ...this.state.timeline_chart.properties,
          max: max,
          min: min,
        },
        show: showChart
      }
    }, function() {
      if(this.props.results.totals) {
        this.setTotalsChart();
      } else {
        this.setChartAspect();
        window.addEventListener('resize', this.handleResize.bind(this));
      }
    });
  }

  chartDataFormat(value) {

    let format = this.props.format

    if(format === '$X') {
      if(value > 1000) {
        format = '$XM';
      }
    }

    if(format === 'X') {
      if(value > 1000) {
        format = 'XM';
      }
    }

    return formatNumber(format,value);
  };

  chartDateFormat(x) {
    return DateTime.fromISO(x).toFormat('MM-dd');
  };

  setChartAspect() {
    if(this.props.print) {
      this.setState({
        ...this.state,
        totals_chart: {
          ...this.state.totals_chart,
          properties: {
            ...this.state.totals_chart.properties,
            width: 150,
            height: 112
          }
        },
        timeline_chart: {
          ...this.state.timeline_chart,
          properties: {
            ...this.state.timeline_chart.properties,
            width: 280,
            height: 112
          }
        },
        xs: 12,
        sm: 6
      });
    } else {
      if((isScreen('xs') || isScreen('sm'))) {
        this.setState({
          ...this.state,
          totals_chart: {
            ...this.state.totals_chart,
            properties: {
              ...this.state.totals_chart.properties,
              width: 350,
              height: 150,
              aspect_width: 2,
              aspect_height: 1
            }
          },
          timeline_chart: {
            ...this.state.timeline_chart,
            properties: {
              ...this.state.timeline_chart.properties,
              width: 350,
              height: 150,
              aspect_width: 2,
              aspect_height: 1
            }
          }
        });
      } else {
        if(isScreen('md')) {

          this.setState({
            ...this.state,
            totals_chart: {
              ...this.state.totals_chart,
              properties: {
                ...this.state.totals_chart.properties,
                width: 350,
                height: 150,
                aspect_width: 5,
                aspect_height: 1
              }
            },
            timeline_chart: {
              ...this.state.timeline_chart,
              properties: {
                ...this.state.timeline_chart.properties,
                width: 350,
                height: 150,
                aspect_width: 5,
                aspect_height: 1
              }
            }
          });
        } else {
          if(isScreen('lg')) {
            this.setState({
              ...this.state,
              totals_chart: {
                ...this.state.totals_chart,
                properties: {
                  ...this.state.totals_chart.properties,
                  width: 350,
                  height: 150,
                  aspect_width: 1,
                  aspect_height: 1
                }
              },
              timeline_chart: {
                ...this.state.timeline_chart,
                properties: {
                  ...this.state.timeline_chart.properties,
                  width: 350,
                  height: 150,
                  aspect_width: 3,
                  aspect_height: 1
                }
              }
            });
          } else {
            this.setState({
              ...this.state,
              totals_chart: {
                ...this.state.totals_chart,
                properties: {
                  ...this.state.totals_chart.properties,
                  width: 350,
                  height: 150,
                  aspect_width: 2,
                  aspect_height: 1
                }
              },
              timeline_chart: {
                ...this.state.timeline_chart,
                properties: {
                  ...this.state.timeline_chart.properties,
                  width: 350,
                  height: 150,
                  aspect_width: 2,
                  aspect_height: 1
                }
              }
            });
          }
        }
      }
    }
  }

  render() {

    return (
      <Col xs={this.state.xs} sm={this.state.sm} md={this.state.md} lg={this.state.lg} xl={this.state.xl} className="gutter-top-fixed-2">

        <Widget className="bg-gray p-0 m-0">
          <>
          {this.props.show_header && this.state.print ? (
            <>
              <Row className="pt-2">
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <p className="pl-2 pt-1">{this.props.title.toUpperCase()}</p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <h4 className="text-black">
                  {(() => {

                    let value = 0;

                    if(this.props.parent) {
                      value = this.props.results[this.props.root_name][0][this.props.parent][this.props.field];
                    } else {
                      value = this.props.results[this.props.root_name][0][this.props.field];
                    }

                    return <>{formatNumber(this.props.format,value)}</>
                    })()}
                  </h4>
                </Col>
              </Row>
              <hr className="p-0 m-0" />
            </>
          ) : null}
            <div className="d-flex flex-row m-0 p-0 mr-1">
              {this.props.product && this.props.product.poster_id ? (
              <div className="p-0">
                  <Link to={{ pathname: '/app/products/' + this.props.product.id, state: this.props.product_link_passthrough }} className="fs-mini text-muted p-0 m-0">
                    <Poster id={this.props.product.poster_id} w={150} className="border-right" style={{'borderRadius': '2px'}} />
                  </Link>
              </div>
              ) : null }
              <div className="flex-fill p-0 m-0">
                <>
                  {this.state && this.state.totals_chart.loaded && this.state.timeline_chart.loaded ? (
                    <Row className="ml-1 mt-1">
                      <Col xs={12} sm={this.state.timeline_chart.show ? 4 : 12} md={this.state.timeline_chart.show ? 4 : 12} lg={this.state.timeline_chart.show ? 4 : 12} xl={this.state.timeline_chart.show ? 4 : 12}>
                        <div className="text-left">
                          {this.props.show_header && !this.state.print ? (
                          <>
                            <p>{this.props.title.toUpperCase()}</p>
                            <h4 className="text-black">
                            {(() => {

                              let value = 0;

                              if(this.props.parent) {
                                value = this.props.results[this.props.root_name][0][this.props.parent][this.props.field];
                              } else {
                                value = this.props.results[this.props.root_name][0][this.props.field];
                              }

                              return <>{formatNumber(this.props.format,value)}</>
                              })()}
                            </h4>
                            <hr/>
                          </>
                          ) : (
                            <div className="mt-2">&nbsp;</div>
                          )}
                          
                          {this.state.totals_chart.show ? (
                              <Chart
                              chart ={
                                <BarChart width={this.state.totals_chart.properties.width} height={this.state.totals_chart.properties.height} data={this.state.totals_chart.data} margin={{top: 15, right: 0, left: 0, bottom: 0}} padding={{top: 0, right: 0, left: 0, bottom: 0}}>
                                  <XAxis dataKey="name" tick={{fontSize: 10}} />
                                  {this.state.totals_chart.data[0].lb ? (
                                    <Bar dataKey="lb" fill="#999" label={<TotalsChartLabel format={this.props.format} bars={this.state.totals_chart.bars} />} />
                                  ) : null}
                                  <Bar dataKey="y0" fill="#82ca9d" label={<TotalsChartLabel format={this.props.format} bars={this.state.totals_chart.bars} />} />
                                  {this.state.totals_chart.bars > 1 ? (
                                    <Bar dataKey="y1" fill="#0087a9" label={<TotalsChartLabel format={this.props.format} bars={this.state.totals_chart.bars} />} />
                                  ) : null}
                                  {this.state.totals_chart.bars > 2 ? (
                                    <Bar dataKey="y2" fill="#ffc647" label={<TotalsChartLabel format={this.props.format} bars={this.state.totals_chart.bars} />} />
                                  ) : null}
                                  {this.state.totals_chart.bars > 3 ? (
                                    !this.props.results.dates || !dateIsCovid(this.props.results.dates[3]) ? (
                                      <Bar dataKey="y3" fill="#955196" label={<TotalsChartLabel format={this.props.format} bars={this.state.totals_chart.bars} />} />
                                    ) : null
                                  ) : null}
                                  {this.state.totals_chart.bars > 4 ? (
                                    !this.props.results.dates || !dateIsCovid(this.props.results.dates[4]) ? (
                                      <Bar dataKey="y4" fill="#dd5182" label={<TotalsChartLabel format={this.props.format} bars={this.state.totals_chart.bars} />} />
                                    ) : null
                                  ) : null}
                                  {this.state.totals_chart.bars > 5 ? (
                                    <Bar dataKey="y5" fill="#ff6e54" label={<TotalsChartLabel format={this.props.format} bars={this.state.totals_chart.bars} />} />
                                  ) : null}
                                </BarChart>
                              }
                              print={this.state.print}
                              chart_aspect_width={this.state.totals_chart.properties.aspect_width}
                              chart_aspect_height={this.state.totals_chart.properties.aspect_height}
                            />
                          ) : null}
                        </div>
                      </Col>
                      {this.state.timeline_chart.loaded && this.state.timeline_chart.show ? (
                        <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                          <div className="bg-white">
                            <Chart
                              chart ={
                                <LineChart width={this.state.timeline_chart.properties.width} height={this.state.timeline_chart.properties.height} data={this.state.timeline_chart.data} margin={{top: 5, right: 5, left: 0, bottom: 0}}>
                                  <XAxis dataKey="name" tick={{fontSize: 10}} tickFormatter={this.chartDateFormat} />
                                  <YAxis tick={{fontSize: 10}} tickFormatter={this.chartDataFormat} domain={[dataMin => (dataMin * this.state.timeline_chart.properties.min), dataMax => (dataMax * this.state.timeline_chart.properties.max)]} />
                                  <CartesianGrid strokeDasharray="3 3" fill="#fafafa" />
                                  <Tooltip content={this.CustomTooltip} />
                                  <Line type="monotone" dataKey="y0" stroke="#82ca9d" strokeWidth={3} dot={false} />
                                  <Line type="monotone" dataKey="lb" stroke="#999" strokeWidth={1} strokeDasharray="4 1 2" dot={false} />
                                  <Line type="monotone" dataKey="y1" stroke="#0087a9" strokeWidth={1} strokeDasharray="4 1 2" dot={false} />
                                  <Line type="monotone" dataKey="y2" stroke="#ffc647" strokeWidth={1} strokeDasharray="4 1 2" dot={false} />
                                  {!this.props.results.dates || !dateIsCovid(this.props.results.dates[4]) ? (
                                    <Line type="monotone" dataKey="y3" stroke="#955196" strokeWidth={1} strokeDasharray="4 1 2" dot={false} />
                                  ) : null}
                                  {!this.props.results.dates || !dateIsCovid(this.props.results.dates[5]) ? (
                                    <Line type="monotone" dataKey="y4" stroke="#dd5182" strokeWidth={1} strokeDasharray="4 1 2" dot={false} />
                                  ) : null}
                                  <Line type="monotone" dataKey="y5" stroke="#ff6e54" strokeWidth={1} strokeDasharray="4 1 2" dot={false} />
                                </LineChart>
                              }
                              print={this.state.print}
                              chart_aspect_width={this.state.timeline_chart.properties.aspect_width}
                              chart_aspect_height={this.state.timeline_chart.properties.aspect_height}
                            />
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                  ) : null }
                </>
              </div>
            </div>  
          </>    
        </Widget>
      </Col>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetDataPointCard));
